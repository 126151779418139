import { Button, Col, DatePicker, Input, message, Row, Select, Space } from "antd";
import React, { useEffect, useRef } from "react";
import { Controller, useForm } from "react-hook-form";
import { useHistory, useParams } from "react-router-dom";
import { Api } from "../../api";
import { FieldErrorMessage } from "../FieldErrorMessage";
import { Helmet } from "react-helmet";
import moment from "moment";

const WebsiteUsersForm = () => {
  let { id }: any = useParams();
  const { handleSubmit, errors, watch, control, reset } = useForm();
  let history = useHistory();
  let password: any = useRef({});
  password.current = watch("password", "");
  const onSubmit = (data: any) => {
    if (!id) {
      Api.websiteUsers.create(data).then((res) => {
        message.success("User Created");
        history.push("/users");
      });
    } else {
      Api.websiteUsers.update({ id: id, ...data }).then((res) => {
        message.success("User Saved");
      });
    }
    // if(data.password!==data.confirmPassword){
    //     return message.error("")    // }
  };

  useEffect(() => {
    if (id) {
      Api.websiteUsers.single(id).then((res) => {
        reset(res.data.data);
      });
    }
  }, [id, reset]);

  return (
    <form className="white-container" onSubmit={handleSubmit(onSubmit)}>
      <Space size="middle" style={{ width: "100%" }} direction="vertical">
        <Helmet>
          <title>Create User | {process.env.REACT_APP_PROJECT_NAME}</title>
        </Helmet>
        <Row gutter={12}>
          <Col span={6}>
            <label htmlFor={"firstName"}>First Name</label>
            <Controller
              control={control}
              as={<Input id={"firstName"} />}
              rules={{ required: true }}
              name="firstName"
            />
            <FieldErrorMessage errors={errors} name="firstName" />
          </Col>
          <Col span={6}>
            <label htmlFor={"lastName"}>Last Name</label>
            <Controller
              control={control}
              as={<Input id={"lastName"} />}
              rules={{ required: true }}
              name="lastName"
            />
            <FieldErrorMessage errors={errors} name="lastName" />
          </Col>
          <Col span={6}>
            <label htmlFor={"careerLevel"}>Career Level</label>
            <Controller
              control={control}
              as={<Input id={"careerLevel"} />}
              rules={{ required: false }}
              name="careerLevel"
            />
            <FieldErrorMessage errors={errors} name="careerLevel" />
          </Col>
          <Col span={6}>
            <label htmlFor={"major"}>Major</label>
            <Controller
              control={control}
              as={<Input id={"major"} />}
              rules={{ required: false }}
              name="major"
            />
            <FieldErrorMessage errors={errors} name="major" />
          </Col>
        </Row>
        <Row gutter={6}>
          <Col span={12}>
            <label htmlFor={"email"}>Email</label>
            <Controller
              control={control}
              as={<Input id={"email"} />}
              rules={{ required: true }}
              name="email"
            />
            <FieldErrorMessage errors={errors} name="email" />
          </Col>
        </Row>
        {/* <Row>
          <Col span={6}>
            <label htmlFor={"premiumSubscription.expiry"}>Egroups Expiry </label>
            <div>
              <Controller
                control={control}
                render={({ onChange, value }) => (
                  <DatePicker
                    showTime
                    format="DD/MM/YYYY hh:mm"
                    onChange={(v) => {
                      onChange(v?.toDate());
                    }}
                    value={value ? moment(value) : null}
                    style={{ width: "100%" }}
                  />
                )}
                rules={{ required: true }}
                name="premiumSubscription.expiry"
              />
            </div>
            <FieldErrorMessage errors={errors} name="premiumSubscription.expiry" />
          </Col>
        </Row> */}

        {!id && (
          <Row gutter={6}>
            <Col span={6}>
              <label htmlFor={"password"}>Password</label>
              <Controller
                control={control}
                as={<Input.Password id={"password"} />}
                rules={{ required: true }}
                name="password"
              />
              <FieldErrorMessage errors={errors} name="password" />
            </Col>
            <Col span={6}>
              <label htmlFor={"confirmPassword"}>Confirm Password</label>
              <Controller
                control={control}
                as={<Input.Password id={"confirmPassword"} />}
                rules={{
                  validate: (value) => value === password.current || "The passwords do not match",
                }}
                name="confirmPassword"
              />
              <FieldErrorMessage errors={errors} name="confirmPassword" />
            </Col>
          </Row>
        )}
      </Space>
      <div style={{ marginBottom: 10 }} />
      <Button htmlType="submit" role={"submit"}>
        Submit
      </Button>
    </form>
  );
};

export default WebsiteUsersForm;
