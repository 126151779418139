import { Button, Card, Col, Modal, Row, Spin } from "antd";
import "./fileUploader.css";
import React, { useEffect, useRef, useState } from "react";
import { useUpload } from "react-use-upload";
import { DeleteOutlined, EyeOutlined, UploadOutlined } from "@ant-design/icons";
import { store } from "../../store";
import { ImagesHelpers } from "../../helpers/images";
import { Api, Base_Url } from "../../api";
import SortableList from "../SortableList";
import Axios from "axios";

const FileUploadV2: React.FC<any> = ({ value, onChange, singleValue, isVideoFile = false }) => {
  const [previewImage, setPreviewImage]: [any, any] = useState(null);
  const [loading, setLoading] = useState(false);
  const [previewVisible, setPreviewVisible]: [any, any] = useState(false);
  let [files, setFiles]: any = useState();
  const [fileUploadPath, setFileUploadPath] = useState("");
  let { progress, error, done, response } = useUpload(files, {
    path: fileUploadPath,
    name: "file",
    method: "PUT",
    // fields: { extraData: 'hello!' },
    headers: {
      "Content-Type": files && files[0].type,
    },
  });

  let inputRef: any = useRef(null);
  const handlePreview = (file: any) => {
    setPreviewImage(file);
    setPreviewVisible(true);
  };
  const handleCancel = () => {
    setPreviewVisible(false);
  };
  useEffect(() => {
    if (done && response) {
      if (singleValue) onChange(response.response.path);
      else onChange([...(value || []), response.response.path]);
      setFiles(undefined);
    }
  }, [response, done]);

  const onRemove = (index: string) => {
    let temp = (value as Array<any>).filter((v, ind) => ind !== parseInt(index));
    onChange(temp);
  };

  // alert(value);
  return (
    <div>
      <div style={{ display: "flex" }}>
        {singleValue && value && value !== "" ? (
          <div style={{ marginRight: 5, position: "relative" }}>
            {/\.(gif|jpg|jpeg|tiff|png|webp)$/i.test(value) ? (
              <img
                className="image-uploader-img"
                alt="pl"
                style={{ width: 100, height: 100 }}
                src={ImagesHelpers.formatUrl(value)}
              />
            ) : (
              <div
                style={{
                  width: 100,
                  height: 100,
                  background: "gray",
                  overflow: "hidden",
                  wordBreak: "break-all",
                }}
              >
                {value}
              </div>
            )}
            <div
              className="image-overlay"
              style={{
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
                width: 100,
                height: 100,
                position: "absolute",
                left: 0,
                top: 0,
              }}
            >
              <EyeOutlined
                onClick={() => {
                  handlePreview(ImagesHelpers.formatUrl(value));
                }}
                style={{ color: "white", cursor: "pointer", marginRight: 10 }}
              />
              <DeleteOutlined
                onClick={() => {
                  onChange(null);
                }}
                style={{ color: "white", cursor: "pointer" }}
              />
            </div>
          </div>
        ) : (
          value && (
            <SortableList
              horizontal
              renderItem={({ value, id }: any, index: any) => (
                <div
                  key={value}
                  style={{ marginRight: 5, minWidth: 100, minHeight: 100, position: "relative" }}
                >
                  {/\.(gif|jpg|jpeg|tiff|png)$/i.test(value) ? (
                    <img
                      className="image-uploader-img"
                      alt="pl"
                      style={{ width: 100, height: 100 }}
                      src={ImagesHelpers.formatUrl(value)}
                    />
                  ) : (
                    <div
                      style={{ width: 100, height: 100, background: "gray", overflow: "hidden" }}
                    >
                      {value}
                    </div>
                  )}

                  <div
                    className="image-overlay"
                    style={{
                      display: "flex",
                      alignItems: "center",
                      justifyContent: "center",
                      width: 100,
                      height: 100,
                      position: "absolute",
                      left: 0,
                      top: 0,
                    }}
                  >
                    <EyeOutlined
                      onClick={() => {
                        handlePreview(ImagesHelpers.formatUrl(value));
                      }}
                      style={{ color: "white", cursor: "pointer", marginRight: 10 }}
                    />
                    <DeleteOutlined
                      onClick={() => {
                        onRemove(id);
                      }}
                      style={{ color: "white", cursor: "pointer" }}
                    />
                  </div>
                </div>
              )}
              list={
                Array.isArray(value) &&
                value?.map((v: any, index: any) => ({ id: index + "", value: v }))
              }
              onChange={(v: any) => {
                onChange(v?.map((i: any) => i.value));
              }}
            />
          )
        )}
        {loading && (
          <Card
            style={{
              width: 100,
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              height: 100,
              background: "rgba(0, 0, 0, 0.2)",
            }}
          >
            <Spin />
          </Card>
        )}
      </div>
      <input
        ref={inputRef}
        style={{ width: 0, height: 0, overflow: "hidden" }}
        type="file"
        accept={isVideoFile ? "video/*" : ""}
        onClick={(e: any) => (e.target.value = null)}
        onChange={(e) => {
          if (e.target.files && e.target.files[0]) {
            // console.log(e.target.files[0]);
            const timestamp = new Date().getTime();
            const files = e.target.files;
            const file = e.target.files[0];
            const filename =
              file.name
                .split(".")[0]
                .replace(/[&\/\\#,+()$~%'":*?<>{}]/g, "")
                .toLowerCase() + `_${timestamp}`;
            const fileExtension = file.name.split(".").pop();

            // await uploadImage(`${filename}.${fileExtension}`, file);
            setLoading(true);

            Api.UploadGetSignedUrl({
              fileName: `${filename}.${fileExtension}`,
              mimeType: file.type,
              isVideoFile: isVideoFile,
            }).then((res) => {
              setFileUploadPath(res.data.urls.signedUrl);
              // console.log(files);
              // setFiles(files);
              const options = { headers: { "Content-Type": file.type } };
              Axios.put(res.data.urls.signedUrl, file, options)
                .then((p) => {
                  let returnValue = isVideoFile ? res.data.urls.videoID : res.data.urls.filename;
                  if (singleValue) onChange(returnValue);
                  else onChange([...(value || []), returnValue]);
                })
                .finally(() => {
                  setLoading(false);
                });
            });

            //;
          }
        }}
      />
      {!loading && !error && (
        <Button
          style={{ marginTop: 5 }}
          icon={<UploadOutlined />}
          onClick={() => {
            inputRef.current?.click();
          }}
        >
          Pick File
        </Button>
      )}
      <Modal visible={previewVisible} footer={null} onCancel={handleCancel}>
        <img alt="example" style={{ width: "100%" }} src={previewImage} />
      </Modal>
    </div>
  );
};

export default FileUploadV2;
