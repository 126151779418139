import { Button, Col, Input, message, Result, Row, Space, Switch } from "antd";
import React from 'react';
import { Controller, useForm } from 'react-hook-form';
import { Link, useHistory, useParams } from "react-router-dom";
import { Api } from "../../api";
import { useSubmitForm } from "../../helpers/forms";
import { ImagesHelpers } from "../../helpers/images";
import { useLoadFormData } from "../../hooks/useLoadFormData";
import { FieldErrorMessage } from "../FieldErrorMessage";
import { FileUploader } from "../FileUploader";
import { Helmet } from "react-helmet";
import TextEditorInput from "../TextEditorInput";
import FileUploadV2 from "../fileUploadV2/FileUploadV2";
import CategorySelector from "../categories/CategorySelector";

const BlogForm = () => {
    let { id } = useParams();
    // const [notFound, setNotFound] = useState(false);
    const { handleSubmit, errors, control, reset } = useForm();
    let history = useHistory();
    const onSuccess = () => {
        message.success("Saved");
        history.push('/blogs')
    }
    // const imageFields = [
    //     {
    //         name: "image",
    //         singleValue: true,
    //     }
    // ];

    const [notFound, loading] = useLoadFormData({
        apiCall: Api.blogs.single, id, setValue: reset,
        //  imageFields
    })
    const [loadingSubmit, submit] = useSubmitForm(
        {
            createApiCall: Api.blogs.create,
            updateApiCall: Api.blogs.update,
            onSuccess,
            //  imageFields,
            updateId: id,
        });

    if (notFound)
        return (<Result
            status="404"
            title="404"
            subTitle="Sorry, document does not exist"
            extra={<Button type="primary">

                <Link to="/blogs">Back to list</Link>
            </Button>}
        />)


    return (
        <form className="white-container" onSubmit={handleSubmit(submit)}>
            <Helmet>
                <title>Create Blog | {process.env.REACT_APP_PROJECT_NAME}</title>
            </Helmet>
            <Space size="middle" style={{ width: "100%" }} direction="vertical">
                <Row style={{ width: "100%" }} gutter={12}>
                    <Col span={6}>
                        <label htmlFor={"title"}>Title</label>
                        <Controller
                            control={control}
                            as={<Input id={"title"} />}
                            rules={{ required: true }}
                            name="title"
                        />
                        <FieldErrorMessage errors={errors} name="title" />
                    </Col>
                </Row>
                <Row style={{ width: "100%" }} gutter={12}>
                    <Col span={6}>
                        <label htmlFor={"subtitle"}>Sub Title</label>
                        <Controller
                            control={control}
                            as={<Input id={"subtitle"} />}
                            rules={{ required: true }}
                            name="subtitle"
                        />
                        <FieldErrorMessage errors={errors} name="subtitle" />
                    </Col>
                </Row>
                {/* <Row>
                    <Col span={6}>
                        <label htmlFor={"eGroupExclusive"}>Is E Group Exclusive </label>
                        <Controller
                            control={control}
                            render={({ onChange, value }) =>
                                <Switch checked={value} onChange={onChange} style={{ display: 'block' }} />}
                            // defaultValue={false}
                            valueName="checked"
                            name="eGroupExclusive"
                        />
                        <FieldErrorMessage errors={errors} name="eGroupExclusive" />
                    </Col>
                </Row> */}
                <Col span={6}>
                    <label htmlFor={"category"}>Category</label>
                    <Controller
                        control={control}
                        as={<CategorySelector />}
                        rules={{ required: false }}
                        name="category"
                    />
                    <FieldErrorMessage errors={errors} name="category" />
                </Col>
                <Row>
                    <Col span={12}>
                        <label htmlFor={"thumbnailImage"}>Thumbnail Image</label>
                        <Controller
                            control={control}
                            as={<FileUploadV2 singleValue />}
                            name="thumbnailImage"
                        />
                        <FieldErrorMessage errors={errors} name="thumbnailImage" />
                    </Col>
                </Row>
                {/* <Row>
                    <Col span={12}>
                        <label htmlFor={"files"}>Files</label>
                        <Controller
                            control={control}
                            as={<FileUploadV2 />}
                            name="files"
                        />
                        <FieldErrorMessage errors={errors} name="files" />
                    </Col>
                </Row> */}
                <Row gutter={12}>
                    <Col span={24}>
                        <label htmlFor={"text"}>Text</label>
                        <Controller
                            control={control}
                            as={<TextEditorInput />}
                            name="text"
                        />
                        <FieldErrorMessage errors={errors} name="text" />
                    </Col>
                </Row>

            </Space>
            <div style={{ marginBottom: 10 }} />
            <Button loading={loading || loadingSubmit} htmlType="submit" role={"submit"}>Submit</Button>
        </form>);
};

export default BlogForm;