import { Button, Modal, Skeleton, Space, Table } from "antd";
import React, { useEffect, useState } from "react";
import { useParams } from "react-router";
import { Api } from "../../api";
import { Helpers } from "../../helpers";
import AddRegistrantModal from "./AddRegistrantModal";
import { ExclamationCircleOutlined } from "@ant-design/icons";

const CourseRegistrants = () => {
  let { id } = useParams();

  const [loading, setLoading] = useState(true);
  const [data, setData]: any = useState([]);

  useEffect(() => {
    Api.courses
      .singleWithRegistrants(id)
      .then((res) => {
        setData(res.data.data);
        setLoading(false);
      })
      .finally(() => {
        setLoading(false);
      });
  }, [id]);

  const onRemoveParticipant = (record: any) => {
    Modal.confirm({
      title: 'Are you sure?',
      icon: <ExclamationCircleOutlined />,
      content: 'Do you want to remove this participant, they will no longer be able to access this course',
      onOk() {
        return new Promise(async (resolve) => {
          const res = await Api.courses.removeRegistrantFromCourse(id, record.user?._id);
          setData(res.data.data);

          resolve(true);
        })
      },
      onCancel() {
        console.log('Cancel');
      },
    });
  }



  return (
    <div>
      {loading ? (
        <Skeleton />
      ) : (
        <div>
          <div style={{ marginBottom: 10 }}>
            <AddRegistrantModal
              onChange={(userId) => {
                return new Promise(async (resolve, reject) => {
                  const res = await Api.courses.addRegistrantToCourse(data._id, userId);
                  setData(res.data.data);
                  resolve(true);
                });
              }}
              exclude={data.registrants.map((reg: any) => reg.user._id)}
            />
          </div>
          <Table
            columns={[
              {
                title: "First Name",
                dataIndex: "user",
                key: "firstName",
                render: (user) => <p>{user.firstName}</p>,
              },
              {
                title: "Last Name",
                dataIndex: "user",
                key: "lastName",
                render: (user) => <p>{user.lastName}</p>,
              },
              {
                title: "Registration Date",
                dataIndex: "createdAt",
                key: "createdAt",
                render: (date: Date) => (date ? Helpers.formatDateFull(date) : "-"),
              },
              {
                title: "Email",
                dataIndex: "user",
                key: "email",
                render: (user) => <p>{user.email}</p>,
              },
              {
                title: "Phone",
                dataIndex: "user",
                key: "phone",
                render: (user) => <p>{user?.phones?.mobile}</p>,
              },
                {
                  title: 'Action',
                  key: 'action',
                  render: (_, record) => (
                    <Space size="middle">
                      <Button onClick={() => { onRemoveParticipant(record) }} danger>Remove Participant</Button>

                    </Space>
                  )
                },
            ]}
            dataSource={data.registrants}
          />
        </div>
      )}
    </div>
  );
};

export default CourseRegistrants;
